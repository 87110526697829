import { render, staticRenderFns } from "./BaseTooltipString.vue?vue&type=template&id=f3eab890&"
import script from "./BaseTooltipString.vue?vue&type=script&lang=js&"
export * from "./BaseTooltipString.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseTooltip: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/tooltip/BaseTooltip.vue').default})
